//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EZUIKit from "ezuikit-js";
import config from "../../common/config";
import { getDetail } from "../../common/api";
import yuYue from "../../components/yuyue.vue";
import { reservation } from "../../common/api";
import gold from "../../components/gold.vue";

export default {
  components: {
    yuYue,
    gold,
  },
  data() {
    return {
      accessToken: "", // 萤石云Token
      url: "",
      list: [],
      type: this.$route.query.type,
      popshow: false,
      goldShow: false, //金币
      yuMoney: 0,
      timing: 0,
      networkAnomaly: false,
      timer: null,
    };
  },
  created() {
    this.dataInit();
    setTimeout(() => {
      this.getToken();
    }, 500);
  },
  methods: {
    tuichu() {
      this.networkAnomaly = false;
      console.log("点击关闭");
      this.$router.go(-1);
    },
    absolute() {
      console.log(11);
    },
    // 金币不足
    goldUpdate: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow;
        }, 300);
      };
    })(),
    // 数据初始化
    async dataInit() {
      let data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await getDetail(data);
      if (res.data.code != 200) return;
      if (this.type == 1) {
        this.list = res.data.data;
      } else {
        this.list.push(res.data.data);
      }
      this.yuMoney = res.data.data.deposit_money;
      this.url = res.data.data.monitor_url;
    },
    async getToken() {
      // 发送 POST 请求
      await this.$axios({
        method: "post",
        url: config.ysyURL,
        params: {
          appKey: config.appKey,
          appSecret: config.appSecret,
        },
      }).then((res) => {
        if (res.data.code != 200) return;
        this.accessToken = res.data.data.accessToken;
      });
      //视频初始化
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: "video-container", // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      });
      this.timer = setInterval(() => {
        this.timing += 60000;
        if (this.timing >= 600000) {
          this.stopVideo();
          this.networkAnomaly = true;
          console.log("结束播放");
          clearInterval(this.timer);
          return;
        }
      }, 60000);
    },
    // 停止播放
    stopVideo() {
      this.player.stop();
    },
    // 领养协议 价格， 总价 保证金
    goBuy(price, total, money) {
      this.$router.push({
        path: "/protocol",
        query: {
          price: price,
          total: total,
          money: money,
        },
      });
    },
    pageClose() {
      console.log("点击关闭");
      this.$router.go(-1);
    },
    // 去预约
    gorReserve() {
      this.popshow = true;
    },
    // 关闭预约弹窗
    closePop(val) {
      this.popshow = val;
    },
    adoption: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          reservation({ access_id: this.$store.state.access_id }).then(
            (res) => {
              if (res.data.code == "80001") {
                //金币不足
                this.popshow = !this.popshow;
                this.goldShow = true;
              }
              if (res.data.code == 200) {
                this.popshow = !this.popshow;
                this.$router.go(-1);
              }
            }
          );
        }, 300);
      };
    })(),
  },
};
